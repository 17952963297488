.profile {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.profile_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.profile_topnav_back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profile_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.profile_body_bio {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-right: 14px;
  gap: 10px;
}

.profile_body_bio_left {
  width: 50px;
  height: 50px;
  border-radius: 99999px;
  background-color: grey;
}

.profile_body_bio_right {
}

.profile_body_services {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1px;
}

.profile_body_listelement {
  height: 140px;
  background-color: grey;
  width: calc(33.3% - 0.66px);
}

.profile_body_listelement_top {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
}

.profile_body_listelement_bottom {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
