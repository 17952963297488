/* Skalierungsfaktoren */

/* Mobil/Tablet */
@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .login {
    width: 100%;
    min-height: 100%;
    padding-bottom: 120px;
    background-color: var(--primaryBackground);
  }

  .login_registerform {
  }

  .login_registerform_title {
    font-weight: 500;
    font-size: 24px;
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 8px;
  }

  .login_input {
    border: 2px solid #222222;
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    padding-left: 12px;
    font-size: 1em;
  }

  .login_ctabtn {
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    background-color: var(--lfmainColor);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: white;
    cursor: pointer;
  }

  .login_ctabtn_disabled {
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: #f58700;
    border: 1px solid #f58700;
    cursor: not-allowed;
  }

  .login_divider {
    width: calc(100% - 32px);
    margin-left: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 48px;
  }

  .login_sociallogin {
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}
/* Desktop*/
@media only screen and (min-width: 1050px) {
  .login {
    width: 100%;
    min-height: 100%;
    padding-bottom: 120px;
    background-color: var(--primaryBackground);

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .login_registerform {
    max-width: 600px;
    width: 100%;
  }

  .login_registerform_title {
    font-weight: 500;
    font-size: 24px;
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 8px;
  }

  .login_input {
    border: 1px solid #dddddd;
    box-shadow: 2px 4px 12px #00000027;
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    padding-left: 12px;
    font-size: 1em;
  }

  .login_ctabtn {
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    background-color: var(--lfmainColor);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: white;
  }

  .login_ctabtn_disabled {
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: #f58700;
    border: 1px solid #dddddd;
  }

  .login_divider {
    width: calc(100% - 32px);
    margin-left: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 48px;
  }

  .login_sociallogin {
    height: 48px;
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 12px;
    margin-top: 12px;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

.collabodetail_topnav_bottom {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 2%;
  width: 100%;
  max-width: 600px;
  padding-top: 14px;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
}

.collabodetail_topnav_bottom_tab_active {
  background-color: #ffeedb;
  color: #f58700;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 49%;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
}

.collabodetail_topnav_bottom_tab {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 49%;
  border: 1px solid #dddddd;
  height: 60px;
  border-radius: 8px;
}
