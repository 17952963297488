.orders {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.orders_topnav {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.orders_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.orders_body_bio {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-right: 14px;
  gap: 10px;
}

.orders_body_bio_left {
  width: 50px;
  height: 50px;
  border-radius: 99999px;
  background-color: grey;
}

.orders_body_bio_right {
}

.orders_body_services {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.orders_body_listelement {
  width: 100%;
  border-bottom: 1px solid #dddddd;
}

.orders_body_listelement_top {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
}

.orders_body_listelement_top_left {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.orders_body_listelement_top_left_img {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  object-fit: cover;
}
.orders_body_listelement_top_middle {
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orders_body_listelement_top_right {
  width: 60px;
  text-align: right;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
}

.orders_body_listelement_bottom {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
}

.orders_body_listelement_bottom_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
}

.orders_body_listelement_bottom_row_left {
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orders_body_listelement_bottom_row_middle {
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.orders_body_listelement_bottom_row_right {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
