.home {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
}

.home_topnav {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.home_topnav_left {
  width: calc(100% - 60px);
  height: 50px;
  background-color: lightgrey;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 14px;
}

.home_topnav_right {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home_body {
  width: 100%;
}

.home_body_listelement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  height: 80px;
}

.home_body_listelement_left {
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home_body_listelement_left_img {
  width: 55px;
  height: 55px;
  border-radius: 999px;
}

.home_body_listelement_middle {
  width: calc(100% - 110px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home_body_listelement_middle_toptext {
}

.home_body_listelement_middle_bottomtext {
}
.home_body_listelement_right {
  width: 55px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home_body_listelement_right_left {
}

.home_body_listelement_right_right {
}
